<template>
  <form class="login-form" @submit.prevent="onSubmit">
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="user_id"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: '아이디', mode: 'text' }">
        <dx-required-rule message="아이디를 입력해 주세요" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field='user_pw'
        editor-type='dxTextBox'
        :editor-options="{ stylingMode: 'filled', placeholder: '패스워드', mode: 'password' }">
        <dx-required-rule message="Password is required" />
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field="rememberMe"
        editor-type="dxCheckBox"
        :editor-options="{ text: '아이디 저장하기', elementAttr: { class: 'form-text' } }">
        <dx-label :visible="false" />
      </dx-item>
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true">
        </dx-button-options>
      </dx-button-item>
      <!--dx-item>
        <template #default>
          <div class="link">
            <router-link to="/reset-password">패스워드 재설정</router-link>
          </div>
        </template>
      </dx-item-->
      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">로그인</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";
import notify from 'devextreme/ui/notify'
import auth from "../auth"

import { reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  setup() {
    
    const route = useRoute()
    const router = useRouter()

    const formData = reactive({
      user_id: '',
      user_pw: ''
    });
    const loading = ref(false);

    function onCreateAccountClick() {
      router.push("/create-account");
    }

    async function onSubmit () {
      const {user_id, user_pw} = formData
      loading.value = true
      const result = await auth.logIn(user_id, user_pw)

      if (!result.isOk) {
        loading.value = false;
        notify(result.message, "error", 2000);
      } else {
        router.push(route.query.redirect || "/home");
      }
    }

    async function onSubmitEx() {
      const { email, password } = formData;
      loading.value = true;
      const result = await auth.logIn(email, password);
      if (!result.isOk) {
        loading.value = false;
        notify(result.message, "error", 2000);
      } else {
        router.push(route.query.redirect || "/home");
      }
    }

    return {
      formData,
      loading,
      onCreateAccountClick,
      onSubmit,
      onSubmitEx
    };
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}
</style>
